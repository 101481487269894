.agb-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/banner-1.jpg);
	}

	strong {
		font-weight: 700;
	}

	ul {
		list-style: disc;
		padding: 0 0 0 40px;

		li {
			margin-bottom: 10px;
		}
	}
}